

.cat-list{

	display: flex;
	flex-direction: column;

	gap:10px;
	padding: 23px 13px;
}


.categoria * {
	cursor: pointer;
}

.categoria{


	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-line-pack: center;
	align-content: center;

	position: relative;
	height: 120px;
	width: 100%;
	overflow: hidden;
	box-shadow: rgb(0 0 0 / 45%) 0px 0px 25px -10px;
	transition: all .2s linear;
	border-radius: 13px;
}


.categoria .nome-wrap{
	overflow-wrap: normal;
	width: 60%;
	position: relative;
	display: flex;
	align-items: center;
	-webkit-transition: all .2s linear;
	-o-transition: all .2s linear;
	transition: all .2s linear;
	color: white;
	z-index: 1;
	height: 100%;
	display: block;
	border-radius: 13px;
}



.categoria .nome-wrap::before{

	content: '';
	width: 100%;
	height: 100%;
	/* background-color: rgba(255,255,255); */
	border-radius: 13px;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	-webkit-transform: skewX(-14deg) translateX(50px);
	-ms-transform: skewX(-14deg) translateX(50px);
	transform: skewX(-14deg) translateX(50px);
	z-index: 0;
	position: absolute;

	background-color: inherit;
}




.categoria .nome-cat label{

	font-size: 1.7rem ;
}

.categoria .nome-cat{
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	padding-left: 43px;
}



.img-container{

	width: 40%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	padding-right: 15px;
	max-width: 400px;
}


.arrow-container{
	height: 100%;
	width: 42px;
	border-radius: 13px;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	-webkit-transform: skewX(-14deg);
	-ms-transform: skewX(-14deg);
	transform: skewX(-14deg);
	z-index: 2;
	position: absolute;
	right: -15px;
	background-color: rgba(255,255,255, .5);
}






i.arrow{
	position: absolute;
	bottom:0;
	right: 8px;
	font-size: 1.3rem;
	z-index: 3;
}





.cat-tot-qt{
	position: absolute;
	bottom:50%;
	right: 0px;
	padding:8px 12px;
	z-index: 4;
	border-top-left-radius: 13px;
	border-bottom-left-radius: 13px;
	color:white;

}





.articoli-list-wrap{

	height: 0;
	overflow: hidden;
	transition: height .5s cubic-bezier(0.61, -0.01, 0.29,1.28);
	position: relative;
	top:0


}

.articoli-list-wrap ul{
	list-style: none;
}


.articolo-item{

	border-radius: 13px;
	transition: border-radius 0.2s ease;
	
	margin: 10px 0; 
	position: relative;
	width: 100%;
	height: var( --articolo-item-height );
	background-color: #fff;
	padding: 11px 0px 11px 20px;
	border-bottom: 1px solid rgba(0,0,0,0.06);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	cursor: pointer;
}




.articolo-item {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap:23px;
}



.articolo-item-text label{
	font-size: .75rem;
}

.articolo-item-text > span{

	display:flex;
	align-items:center;
	justify-content: flex-start;
	gap:45px;
}

.articolo-item i{
	height: 100%;
	padding:0 10px;
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	color:var( --red );
	position: absolute;
	right: 10px;
}



.articolo-item-qt{
	color:var( --green );
}


.articolo-item-qt::before{
	content:"";
	position: absolute;
	left: 0px;
	top:10%;
	height: 80%;
	border-left: 3px solid var(--green );
	border-radius: 50px;
}



@media only screen and ( max-width:500px){

	.articolo-item i{
		position: relative;
	}

}

