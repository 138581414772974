@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');




:root{

	--bk-light:#eee;
	--bk-light2:#ddd;
	--green:#00af80;
	--primary:#00af80;
	--red:#e2243b;
	--articolo-item-height:69px;
	--nav-btn-height:95px;
}


* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	user-select: none;
}



body {

	width: 100vw;
	overflow-x: hidden;


	font-size: 18px;

	background-color: var(--bk-light);
	font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
  /*   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
  /*   sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

