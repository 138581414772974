



.orders-page > div:nth-child(1){

	display: flex;
	align-items: center;
	justify-content: center;
	gap:86px;
}


.order-list{
	height: clamp( 300px, 500px ,70vh);
	width: 40vw;
}




.order-list ul{

	list-style: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	gap:23px;
	overflow-y: auto;
	padding:23px 0;
}



.order-item{

	border-radius: 13px;
	transition: border-radius 0.2s ease;
	cursor: pointer;
	
	margin: 10px 0; 
	position: relative;
	width: 100%;
	/* height: var( --articolo-item-height ); */
	height: 65px;
	min-height: 65px;
	background-color: #eee;
	padding: 11px 0px 6px 20px;
	border-bottom: 1px solid rgba(0,0,0,0.06);


	display: grid;

	grid-template-columns: 2fr 1fr 1fr;
	place-items: center;
	align-content: center;
	justify-items: flex-start;

}

.order-item > span:nth-child(2){
	font-size: .9rem;
}


.order-item label {
	background-color: var(--green);
	border-radius: 50px;
	padding:3px 14px;
	position: absolute;
	top:-25%;
	left: 5px;
	color: white;

}

.order-item > i{
	justify-self: center;
}




.riepilogo-ordine {

	/* height: clamp( 300px, 700px ,70vh); */
	max-height: 70vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap:23px;

	position: relative;
	width: clamp( 200px, 20vw, 400px);
}


.riepilogo-ordine > div:nth-child(2){
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.riepilogo-ordine > div:nth-child(2) > span{

	color: var(--green);
	font-size: .95rem;
}

.riepilogo-ordine ul{
	width: 100%;

	overflow-y: auto;
}

.riepilogo-ordine > div:nth-child(4){
	width: 100%;
}



.riepilogo-ordine > div:nth-child(4) > p{
	max-width: 20vw;
	padding:5px;
	border-radius:10px;
	border: 1px solid var(--bk-light );
	text-align: start;
	line-height: 1.4rem;
	font-size: .95rem;
}



.riepilogo-ordine > div:nth-child(4) > label {

	display: block;
	width: 100%;
	text-align: start;
	font-size: .9rem;
	color:var(--green);


}


.riepilogo-ordine > label {
	background-color: var(--green);
	border-radius: 50px;
	padding:3px 20px;
	transform: translateY(-120%);
	color: white;

	font-size: 1.7rem;
	z-index: 2;
	position: absolute;
}
