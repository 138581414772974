
.piatti-sec .variante-item{
	display: flex;
	width: 50%;
	justify-content: space-between;
	padding:35px 20px;
}


.floating-menu{
	position: absolute;
	bottom:0;
	left:0;
	background-color: var(--light-background );
	width: 100%;
	height: 100%;
	z-index: 10;
	overflow-y: hidden;

	display: flex;
	flex-direction: column;
}

.floating-menu > ul  {
	color: var( --light-text);
}

.articoli-page{
	display: grid;
	height:calc( 100vh - 120px ); 
	/* grid-template-rows: .3fr .5fr .2fr; */
	grid-template-columns: repeat( 5, 20vw );
	grid-template-rows: repeat( 2, auto);
}

.quantita-sec{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap:30px;
	height: 140px;
	padding-bottom: 25px;
	width: 50%;
	padding-left: 10px;
}


.insert-sec{
	width: 100%;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr .3fr;
	place-items: center;
}

.insert-sec > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	gap:10px;
	padding: 10px;
}

.multi-sec{
	display: inherit;
	width: 100%;
	height: 50%;
}

.portate-sec{
	grid-column: 1 ;
	display: flex;
	flex-direction: column;
}


.nome-variante p:nth-child(2){
	font-size: .8rem;
	margin-top:4px;

}

.piatti-sec{
	grid-column: 2 / 6 ;
	width: 100%;
	overflow-y: hidden;
	position: relative;
	background-color: var(--light-background );
}



.piatti-sec > ul{
	text-align: right;
	color: var( --light-text);
	overflow-y: auto;
	height: 40vh;
}

.piatti-sec li{
	padding:10px 20px;
	position: relative;
	border-bottom: 1px solid;
	/* color: var( --background ); */
	color:black;
}



.articoli-page ul{
	list-style: none;
	overflow-y: auto;
	height: 100%;
}



.portate-sec > div {
	height: 40vh;
}

.portate-sec ul {
	height: 75%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding:4px;
	overflow-y: auto;
}



.portate-sec li {
	background-color: #fdfdfd;
	border-bottom: 1px solid black;
	padding: 10px 5px 10px 10px;
	box-shadow: -6px 0px 29px -6px black inset;
	border-radius: 10px;
	background: linear-gradient(145deg, #cdccc9, #adaca9);
	box-shadow: 5px 5px 2px var(--background), -5px -5px 8px #868686;
}

.portate-sec li:active {
	box-shadow: 0px 0px 10px -4px black inset;
}



.bottom-floating{
	display: flex;
	justify-content: space-around;
	min-height: 180px;
	position: absolute;
	bottom: 0;
	background-color: var(--light-background);
	padding-top: 20px;
	width: 100%;
}


.name-sec {
	width: 50%;
}
.name-sec .button-wrap{
	max-width: 70px;
}

@media only screen and ( max-width:830px){


	.btn-multi{
		min-width:70px;
	}


	.quantita-sec .text-input-wrap{
		margin-right: 25px;
	}


	.portate-sec{
		grid-column: 1 / 3 ;
	}

	.piatti-sec{

		grid-column: 3 / 6;
	}
	.insert-sec {
		grid-column: 4/6;
	}

	.insert-sec .select-input{
		width:150px;
	}
	
}

@media only screen and ( max-width:500px){

	.multi-sec{
		display: grid;
		grid-template-columns: 1fr 1fr;
		place-items: center;
		gap:2px;
		height:200px;
	}

	.insert-sec .select-input{
		width:100px;
	}

	.portate-sec li {
		font-size: .9rem;
	}
	
}










