



.page{
	height: 100vh;
	position: relative;
}



ul{
	list-style: none;
}


.page.accounts{
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: clamp( 30px, 53px, 10vh);
}



.page.orders-page{

	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap:clamp( 23px, 5vw,  230px)  ;
	padding: 0 53px;
}

.page .orders{
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap:clamp( 23px, 10vw,  230px)  ;
	padding: 0 53px;
}





.page.login{
	display: flex;
	align-items: center;
	justify-content: center;
}


.page.menu {
	height: calc( 100vh - var(--nav-btn-height));
	height: 100vh;
	padding-bottom: var(--nav-btn-height);
	overflow-y: auto;
}

.page.payment {
	/* height: calc( 100vh - var(--nav-btn-height)); */
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	padding-top: var(--nav-btn-height);
	padding-bottom: 34px;
}




.header-over{

	width: 100%;
	height: 250px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .25);

}

.page > header {
	width: 100%;
	height: 250px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 0;
	backdrop-filter: blur(50%);
}



.brand{
	width: 100%;
	min-height: 100px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #fff;
	/* padding-top: 22px; */
	z-index: 2;
	position: relative;
}


.brand img{
	max-width: 15vw;
}

.brand > div  {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding:20px 20px;
}

.brand > div:nth-child(1)  {
	border-bottom: 1px solid #e3e3e3;
}





.login-card{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding:34px;
	gap:53px;
}




