

.card{
	background-color: white;
	display: flex ;
	justify-content: center;
	align-items: center;
	
	max-width: 700px;
	background-color: #fff;
	padding: 30px 50px;
	text-align: center;
	border-radius: 10px;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);


}




.dialog{

	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	align-items: center;
	gap:20px;
	position:relative;
}


.dialog > i{

	font-size: 60px;
	position: absolute;
	color: var( --green );
	top:-63px;
	display: flex;
	justify-content: center;
	align-items: center;
}


.dialog.error-dialog > i{
	color: var( --red );
}



.dialog > i > div {

	height: 50px;
	width: 50px;
	border-radius: 50%;
	position: absolute;
	background-color: white;
	z-index: -1;
}




.dialog > div {

	width: 100%;
	display: inherit;
	align-items: inherit;
	justify-content: space-evenly;
}


.dialog details{
	width: 100%;
}
