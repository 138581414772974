


.select-input select{

	background-color: white;
	color: black;

	border-color: var(--green);

	padding:7px 17px;
	border-radius: 13px;
	font-size: 1.1rem;
}
