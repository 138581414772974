



.account-list{

	display: flex;
	flex-direction: column;
	gap:23px;
	max-height: 70vh;
	overflow-y: auto;
}



.account-row{
	min-width: 60vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.account-row > div {
	width: clamp( 50px, 100px, 30%);
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.account-row > div > i {
	font-size: 1.5rem;
}

.account-row > div > i:nth-child(1) {
	color: var(--green);
}

.account-row > div > i:nth-child(2) {
	color: var(--red);
}



.user-form{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap:32px;
}

.user-form input{

	color:black !important ;
}
.user-form .btn-group{
	display: flex;
	gap:38px;
}
