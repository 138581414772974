




.payment section {
	padding:23px 12px;
	min-width: 100%;
}


.payment section h3 {
	margin-bottom: 13px ;
}


.payment section textarea {
	resize: none;
	width: 100%;
	height: 100px;
	border: none;
	border-radius: 13px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
	padding: 12px 20px;
	font-size: 14px;
	resize: vertical;
	min-height: 45px;
	user-select: text;
}



.articolo-item.riepilogo {
	cursor: default;
	margin: 0;
}




.articolo-item.riepilogo > div:nth-child(3) {
	display: flex;
	align-items: center;
	justify-content: center;
	gap:34px;
	width: 100px;
	height: 100%;
	position: absolute;
	right: 20px;
}

.articolo-item.riepilogo > div:nth-child(3) i {
	cursor: pointer;
	position: relative;
	color: var(--green);
	transition:transform .3s ease-out;
}

.articolo-item.riepilogo > div:nth-child(3) i:active {

	transform: scale(90%);
}

@media only screen and ( max-width:500px){

	.articolo-item.riepilogo > div:nth-child(3){
		position: relative;
	}

}
