

.background {

	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	z-index: -10;

}


.background > svg:nth-child(1){
	position: fixed;
	top:0;
	left:0;
	z-index: -1;
}


.background > div:nth-child(2){
	position: fixed;
	top:0;
	left:0;
	z-index: -2;
	/* transform: ; */
	background-color: var(--green);
	height: 88px;
	width: 600px;
}



.background > svg:nth-child(2){
	position: fixed;
	bottom:0;
	left:0;
	z-index: -1;
}
