

.gestione-menu{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 5vh;
}


.articolo-form li{
	cursor: pointer;
}


.articolo-form >p {
	margin: 45px 0;
}

.articolo-form input{
	color:black !important;
}

.selected-portata{
	background:linear-gradient(145deg, #cdccc9, #d2b767) !important;
}


.articolo-form{
	display: flex;
	flex-direction: column;
} 

.articolo-form .input-group{

	padding: 43px 13px;
	display: flex;
	flex-direction: column;
	gap:53px;
}

.articolo-form .btn-group{

	display: flex;
	gap:54px;
	align-items: center;
	justify-content: center;
}

.menu-sec{

	width:60vw;
	display: flex;
	flex-direction: column;
	gap:20px;
	max-height: 60vh;
	overflow-y: auto;
}


.menu-cat{
	width: 100%;
	overflow-y: hidden;
}


.menu-cat .articoli-wrap{
	transition: height .2s ease-out;
	display: flex;
	flex-direction: column;
	gap:20px;
	padding:0 5px;
}


.menu-cat >div:nth-child(1) > span{
	gap: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu-cat >div:nth-child(1)  i{
	font-size: 1.5rem;
	color: var(--red);
}


.menu-cat >div:nth-child(1)  i:nth-child(1){
	color: var(--green);
}



.menu-cat > div:nth-child(1) {
	width: 100%;
	padding: 13px;
	display: grid;
	/* justify-items: flex-start; */
	/* align-items: center; */
	gap: 34px;
	grid-template-columns: .5fr 1fr .7fr auto;
	place-items: center;
}




.menu-cat >div:nth-child(1) >label {
	font-size: 1.5rem;
	text-transform: uppercase;
}


.starfood-art{

	display: flex;
	max-height: 40vh;
	width:100%;
	max-width:55vw;
	justify-content: center;

}



.menu-art{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.menu-art i {
	font-size: 1.5rem;
	color:var( --red );
}


.menu-art >div:nth-child(1) {
	text-align: left;
	line-height: 23px;
}


.menu-art >div:nth-child(2) {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.menu-art >div:nth-child(2) > i:nth-child(1) {
	color: var(--green);

}
