




.sidebar-wrap i {
	position: fixed;
	top:30px;
	right: 30px;
	font-size: 2.2rem;
	cursor:pointer;
	min-height: 50px;
	min-width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding:17px;
	background-color: var( --bk-light2);
	border-radius: 50%;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	z-index: 10;
	transition: transform .2s ease-out;
}

.sidebar-wrap i:active {
	transform: scale(90%);
}


.sidebar{
	position: absolute;
	top: 0;
	height: 90vh;
	width: 250px;
	z-index: 10;
	background-color: var(--bk-light2);
	
	border-radius: 10px;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	border-right: 4px ridge #ddd;
	border-bottom: 4px ridge #ddd;
	border-top-left-radius: 0;
	left:-105%;
	transition: left .3s ease ;
	/* cubic-bezier(.18,.89,.32,1.28); */
}


.sidebar-active{
	left:0;
}



.sidebar > div {

	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}



.sidebar li:nth-child(1){
	
	border-top: 1px solid;
}

.sidebar li{
	cursor: pointer;
	height:73px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid;
	padding-left: 13px;
}
