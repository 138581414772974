




.switch-wrap{
	display: flex;
	align-items: center;
	justify-content: center;
	gap:35px;
}

.switch-wrap > span{

	color: var(--green);
	text-transform: capitalize;
	transform: scale(90%);

}


.text-area{
	resize: none;
	border-radius: 10px;
	width: 100%;
	min-height: 100px;
	padding:13px;

	
}


.text-area-wrap{
	display:flex;
	justify-content:center;
	align-items:center;
	position:relative;
	z-index:2;
	min-width: 249px;
}

.text-area-label{

	position: absolute;
	top:-23px;
	left:0;
	transform: scale(90%);
	color: var(--green );

}

.digit-input{
	display: flex;
	align-items: center;
	gap:4px;
	flex-direction:column;
}


.label-fix{

        position:absolute; 
        width:auto;
        white-space:nowrap;
        overflow:hidden;  
        text-overflow:ellipsis;
 
        left:10px;
        /* background-color:#fff; */
        padding-left:5px;
        padding-right:5px;

        transform:translate( -17px, -27px ) scale( 90% );
        transition: .3s ease transform, .3s ease color;
        z-index:4;
        color: var( --green);
        
        /* font-family: 'Montserrat', sans-serif; */
        /* font-weight:bold; */
}




.digit-label{

	width: 100%;
	display: block;
	/* padding-left:17px; */
	text-align: center;
	font-size: 1.2rem ;
	color: var(--senape);

}

.text-input-wrap{
	display:flex;
	justify-content:center;
	align-items:center;
	position:relative;
	width:min-content;
	z-index:2;
}


.text-input{
	user-select:text;
	height:40px;
	width:200px;
	max-width:200px;
	padding: 0 28px 0 13px;
	position:relative;
	border:none;
	z-index:0;
	background-color:transparent;
	border-bottom: 1px solid var( --bk-light2 );
	transition: .3s ease background-color, .3s ease color;
	font-size: 1rem;
}



.text-input:disabled{
	font-size: 1.3rem;
	color: white;
}

.text-input:disabled ~ .text-input-icon{
	color: white ;
}

.short {
	width: 130px;
}

.shorter {
	width: 70px;
}

.text-input-label{
	position:absolute;
	width:auto;
	transform:translateX(13px);
	transition: .3s ease transform, .3s ease color, .3s ease font-weight, .3s ease left;
	z-index:4;

	left:10px;
	padding-left:5px;
	padding-right:5px;
	background-color:#fff;

	color:var( --bk-light2 );
	font-family: 'Montserrat', sans-serif;
	font-weight:normal;
}

.text-input-label-active{
	color:var( --primary );
	transform:translate( -11px, -22px ) scale( 90% );
	font-weight:bolder;
	left:10px;
}




.border-wrap{
	height:43px;
	width:206px;
	position:absolute;
	z-index:1;
	background:none;
	pointer-events:none;
}

.border-wrap.short {
	width: 130px;
}

.border-wrap.shorter {
	width: 70px;
}

.border{
	position:absolute;
	z-index:2;
	transition: .3s ease height, .3s ease width, .3s ease max-width;
	border:none;
	width:0;
	height:0;
	border-radius:10px;
}



.border-top{
	border-top:3px solid var( --primary );
	top:0;
	right:0;
}

.border-bottom{
	border-bottom:3px solid var( --primary );
	bottom:0;
	left:0;
}

.border-left{
	border-left:3px solid var( --primary );
	bottom:0;
	left:0;
}


.border-right{
	border-right:3px solid var( --primary );
	top:0;
	right:0;
}


.border-lr-active{
	height:100%;
}

.border-tb-active{
	width:100%;
}




.text-input:focus{
	outline:none;
}


.text-input-border-active{
	outline: 2px solid var( --primary );
}




.text-input-icon{
	position:absolute;
	right:0;
	position: absolute;
	right: 9px;
	bottom: 8px;
	z-index:3;
}

.text-input-icon-active{

	color:var( --background );

}






input[type=number]::-webkit-inner-spin-button,
input[data-number=number]::-webkit-inner-spin-button,
input[data-number=number]::-webkit-outer-spin-button
{ 
  -webkit-appearance: none;
  margin: 0;
}

input[type=number],
input[data-number=number]
{
        padding-right:3px;
  -moz-appearance: textfield;
  	/* width:110px; */
	/* width:33vw; */
}

input[data-number=number] ~ .border-wrap{
	/* width:33vw; */
}


.quantity-nav {
  float: left;
  position: relative;
  height: 62px;
}

.quantity-button {
  position: absolute;
  right:-9px;
  top:-33px;
  cursor: pointer;
  width: 20px;
  text-align: center;
  float:right;
  height: calc( 100% + 10px) ;
  color: var(--primary);
  font-size: 2rem;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transform:translate(20px, 5px);
  z-index:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.quantity-up {
  height: 2%;
  z-index:1;
}

.quantity-down {
  height: 2%;
  z-index:1;
}

.quantity-down:active {
	transform:scale(90%);
}


.quantity-up:active {
	transform:scale(90%);
}


@media only screen and ( max-width:350px ){
	.text-input-label-active{
		transform:translate( -9px, -20px ) scale( 90% );
	}
}




