

.btn{
	min-height:40px;
	min-width: 200px;
	padding:0px 22px;
}

.btn-icon{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap:13px;
	padding: 0 20px 0 34px;
	font-size: 1rem;
}


.button i * {
	display: inherit;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	stroke: white !important;
}



.button-wrap{
	position:relative;
	border-radius:20px;
	min-width:120px;
	z-index:2;
}



.button{
	display:flex;
	text-align:left;
	justify-content:center;
	gap:13px;
	align-items:center;

	position:relative;
	overflow:hidden;
	min-width:120px;
	max-width:220px;
	height: 100%;
	width: 100%;
	padding: 12px 22px;



	background-color:var(--green );
	/* color:var( --background ); */
	color:white;
	/* border:2px solid var( --dark-senape );  */
	border-radius:13px;
	/* border:2px solid var( --primary ); */
	/* color:#007700; */
	cursor:pointer;
	transition: .2s ease color;
	z-index:1;
	font-size: large;
	text-align: center;
}


.btn-multi{

	min-width: unset;
	max-width: unset;
	min-height: unset;
	max-height: unset;
	width: 25%;
	height: auto;
}

.btn-multi * {
	min-width: unset;
}

.btn-back{

	border-radius:50%;
	background: var( --primary );
	position:absolute;
	transform: translate( -50%,-50%);
	z-index:-1;
}




.red{
	border:2px solid var( --red );
	color: white;
	background: var( --red );

}

.red ~ .btn-back-deactive{
	background: var( --red );
}

.red ~ .btn-back-active{
	background: var( --red );
}


.blue{
	border:2px solid var( --blue );
	color: var( --blue );

}

.blue ~ .btn-back-deactive{
	background: var( --blue );
}

.blue ~ .btn-back-active{
	background: var( --blue );
}



.orange{
	border:2px solid var( --orange );
	color: var( --orange );

}

.orange ~ .btn-back-deactive{
	background: var( --orange );
}

.orange ~ .btn-back-active{
	background: var( --orange );
}


.green{
	border:2px solid green ;
	
	color: white;
	background: green ;

}

.green ~ .btn-back-deactive{
	background: green ;
}

.green ~ .btn-back-active{
	background: green ;
}




.button:active{
	transform:scale(95%);
}



.btn-back-active{
	animation: .2s ease open-circle forwards;
}

.btn-back-deactive{
	animation: .2s ease close-circle forwards;
}



.nav-button-wrap {
	width: 100%;
	height: var( --nav-btn-height );
	display: flex;
	justify-content: center;
	align-items: center;
	gap:23px;
	position: fixed;
	bottom: 0;
	z-index: 5;
	background: rgba( 255, 255, 255, 0.35 );
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 10px );
	-webkit-backdrop-filter: blur( 20px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
}


.nav-button-wrap > label {
	font-weight: bold;
	padding:3px 5px;
	background: rgba( 255, 255, 255, 0.55 );
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 10px );
	-webkit-backdrop-filter: blur( 20px );
	border-radius: 5px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
}




@keyframes open-circle {
	0%{
		height:0;
		width:0;
	}
	100%{
		height:50vh;
		width:50vh;
	}
}



span.ripple {
	position: absolute;
	border-radius: 50%;
	background: var( --primary );
	transform: translate( -50%, -50%) scale(0) ;
	animation: ripple .5s linear;
}

@keyframes ripple {

	0% {
    		transform: translate( -%50, -50%) scale(0);
    		opacity:1;
	}

  	100% {
    		transform: translate( -50%, -50%) scale(4);
    		opacity:0;
  	}
}





@keyframes close-circle {
	0%{
		height:50vh;
		width:50vh;
	}
	100%{
		height:0;
		width:0;
	}
}



